<template lang="pug">
    .main-wrapper.empresa-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "empresas" }'> Empresas</router-link> /
                            <b>{{ model.id ? 'Editar' : 'Adicionar' }}</b>

            DialogUsuario(
                v-if="usuario.dialog"
                :visible="usuario.dialog"
                :model="usuario.model"
                :cd_empresa="model.id"
                @close="usuario.dialog = false"
                @saved="getUsuariosEmpresa()")

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm || waiting' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } empresas`")
                        .p-grid.p-fluid.p-align-end

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_razao_social.$error }")
                                label.form-label Razão social:
                                InputText(type='text' v-model='$v.model.nm_razao_social.$model')
                                .feedback--errors(v-if='submitted && $v.model.nm_razao_social.$error')
                                    .form-message--error(v-if="!$v.model.nm_razao_social.minLength") <b>Razão Social</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_razao_social.required") <b>Razão Social</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_cnpj.$error }")
                                label.form-label CNPJ:
                                InputMask(mask='99.999.999/9999-99' v-model='$v.model.nr_cnpj.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_cnpj.$error')
                                    .form-message--error(v-if="!$v.model.nr_cnpj.minLength") <b>CNPJ</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nr_cnpj.required") <b>CNPJ</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_fantasia.$error }")
                                label.form-label Nome fantasia:
                                InputText(type='text' v-model='$v.model.nm_fantasia.$model')
                                .feedback--errors(v-if='submitted && $v.model.nm_fantasia.$error')
                                    .form-message--error(v-if="!$v.model.nm_fantasia.minLength") <b>Nome Fantasia</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_fantasia.required") <b>Nome Fantasia</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_responsavel.$error }")
                                label.form-label Responsável:
                                InputText(type='text' v-model='$v.model.nm_responsavel.$model')
                                .feedback--errors(v-if='submitted && $v.model.nm_responsavel.$error')
                                    .form-message--error(v-if="!$v.model.nm_responsavel.minLength") <b>Responsável</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_responsavel.required") <b>Responsável</b> é obrigatório.

                            .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                label.form-label CEP:
                                InputMask(mask='99999-999' v-model='$v.model.nr_cep.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                    .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nr_cep.required") <b>CEP</b> é obrigatório.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nm_rua.$error }")
                                label.form-label Rua:
                                InputText(type='text' v-model='$v.model.nm_rua.$model')
                                .feedback--errors(v-if='submitted && $v.model.nm_rua.$error')
                                    .form-message--error(v-if="!$v.model.nm_rua.minLength") <b>Rua</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_rua.required") <b>Rua</b> é obrigatório.

                            .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_empresa.$error }")
                                label.form-label Número:
                                InputText(type='text' v-model='$v.model.nr_empresa.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_empresa.$error')
                                    .form-message--error(v-if="!$v.model.nr_empresa.minLength") <b>Número da Empresa</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nr_empresa.required") <b>Número da Empresa</b> é obrigatório.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nm_bairro.$error }")
                                label.form-label Bairro:
                                InputText(type='text' v-model='$v.model.nm_bairro.$model')
                                .feedback--errors(v-if='submitted && $v.model.nm_bairro.$error')
                                    .form-message--error(v-if="!$v.model.nm_bairro.minLength") <b>Bairro</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_bairro.required") <b>Bairro</b> é obrigatório.

                            .p-col-12.p-md-6
                                label.form-label Complemento:
                                InputText(type='text' v-model='model.ds_complemento')

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_telefone.$error }")
                                label.form-label Telefone:
                                InputMask(:mask="'(99) 99999999?9'" v-model='$v.model.nr_telefone.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_telefone.$error')
                                    .form-message--error(v-if="!$v.model.nr_telefone.minLength") <b>Telefone</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nr_telefone.required") <b>Telefone</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.ds_email.$error }")
                                label.form-label Email:
                                InputText(type='text' v-model='$v.model.ds_email.$model')
                                .feedback--errors(v-if='submitted && $v.model.ds_email.$error')
                                    .form-message--error(v-if="!$v.model.ds_email.minLength") <b>Email</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.ds_email.required") <b>Email</b> é obrigatório.

                            .p-col-12.p-md-3
                                ProgressBar(v-if="waitingEstado" mode="indeterminate")
                                div(v-else)
                                    label.form-label Estado:
                                    Dropdown(v-model='model.cd_estado' :options='options.ufs' @change='getCidades()'
                                        optionLabel='textAlt' optionValue='value' placeholder='Selecione' filter)

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.cd_cidade.$error }")
                                ProgressBar(v-if="waitingCidade" mode="indeterminate")
                                div(v-else)
                                    label.form-label Município:
                                    Dropdown(v-model='model.cd_cidade' :options='options.cidades'
                                        optionLabel='text' optionValue='value' placeholder='Selecione' filter)
                                .feedback--errors(v-if='submitted && $v.model.cd_cidade.$error')
                                    .form-message--error(v-if="!$v.model.cd_cidade.required") Campo obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.cd_tipo_empresa.$error }")
                                ProgressBar(v-if="waitingTipoEmpresa" mode="indeterminate")
                                div(v-else)
                                    label.form-label Tipo de Empresa:
                                    Dropdown(v-model='model.cd_tipo_empresa' :options='options.tiposEmpresa'
                                        :disabled="!!model.id" optionLabel='text' optionValue='value' placeholder='Selecione')
                                .feedback--errors(v-if='submitted && $v.model.cd_tipo_empresa.$error')
                                    .form-message--error(v-if="!$v.model.cd_tipo_empresa.required") Campo obrigatório.
                            .p-col-12.p-md-3
                                label.form-label ISS retido:
                                InputSwitch( v-model='model.ie_iss_retido')
                            .p-col-12.mt-2.mb-2(v-if="model.id")
                                .p-grid
                                    .p-col-12.p-md-6.p-lg-3
                                        span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12.ta-right
                                Button(label='Salvar' icon='jam jam-check' type="submit" style='max-width:160px')

            Panel.datatable.mt-3(header="Usuários" v-if="model.id")

                ProgressBar(v-if='usuario.waiting || waiting' mode="indeterminate")

                div(v-else-if="!usuarios.length")
                    p.ta-center Nenhum usuário cadastrado!

                .ta-center(v-else)
                    DataTable(:value="usuarios")
                        Column(headerStyle='width: 5%;' bodyStyle='text-align: center;')
                            template(#body='props')
                                ProgressSpinner.waiting-status(v-if='usuario.statusIds.includes(props.data.id)' strokeWidth='6')
                                .status-indicator(
                                    v-else :class="{ off: ! props.data.is_active }"
                                    @click='alterarStatusUsuario(props.data)')
                                    i.jam(:class="{ 'jam-check': props.data.is_active, 'jam-minus': !props.data.is_active }")
                        Column(headerStyle='width: 35%;' bodyStyle='text-align: left;' field='full_name' header='Nome')
                        Column(headerStyle='width: 25%;' field='username' header='Usuário')
                        Column(headerStyle='width: 25%;' field='email' header='Email')
                        Column(headerStyle='width: 10%;' header="Ações")
                            template(#body='props')
                                .ta-center
                                    Button.p-button-raised.p-button-rounded.mr-1(
                                        v-tooltip.top="'Editar'"
                                        type="button"
                                        icon="jam jam-write"
                                        @click="onpenDialogUsuario(props.data)"
                                    )

                .p-grid.p-fluid.p-align-end.mt-2
                    .p-col-12.ta-right
                        Button(
                            label='Adicionar'
                            icon='jam jam-plus'
                            style='max-width: 160px'
                            :disabled='usuario.waiting'
                            @click="onpenDialogUsuario()")

            Panel.datatable.mt-3(
                :header="`Lista de ${model.cd_tipo_empresa === 1 ? 'pacientes' : 'associados'}`"
                v-if="model.id")

                .p-grid.p-fluid.p-align-end
                    .p-col-8
                        label.form-label Nome:
                        InputText(type='text' v-model='filters.nm_pessoa_fisica' @keyup.enter="filtrarAssociados()")

                    .p-col-4
                        label.form-label CPF:
                        InputMask(
                            :mask="'999.999.999-99'"
                            v-model="filters.nr_cpf"
                            @keydown.enter="filtrarAssociados()"
                            :autoClear="false")

                    .p-col-12.ta-right
                        Button(
                            label='Filtrar'
                            icon='jam jam-search'
                            style='max-width: 160px'
                            :disabled='waitingAssociados'
                            @click="filtrarAssociados()")

                ProgressBar(v-if='waitingAssociados || waiting' mode="indeterminate")

                div(v-else-if="!listaAssociados.length")
                    p.ta-center Nenhum item encontrado

                div(v-else)
                    Paginator.mb-1(
                        :rows='paginatorAssociados.per_page'
                        :first='(paginatorAssociados.page - 1) * paginatorAssociados.per_page'
                        :totalRecords='paginatorAssociados.count'
                        @page="onPageAssociados")
                    DataTable(:value="listaAssociados")
                        Column(headerStyle='width: 25%;' field='nm_pessoa_fisica' header='Nome')
                        Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_cpf_f' header='CPF')
                        Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' field='dt_nascimento_f' header='Data de nascimento')
                        Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='ie_sexo_f' header='Sexo')
                        Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' field='nr_telefone_f' header='Telefone')
                        Column(headerStyle='width: 18%;' header='E-mail')
                            template(#body='props')
                                span(v-tooltip.top="props.data.ds_email" style='cursor: pointer;') {{ props.data.ds_email_f }}
                        Column(headerStyle='width: 8%; text-align: center')
                            template(#header) Ações
                            template(#body='props')
                                .ta-center
                                    Button.p-button-raised.p-button-rounded.mr-1(
                                        v-tooltip.top="'Editar'"
                                        type="button"
                                        icon="jam jam-write"
                                        @click="$router.push(`/paciente/salvar/${ props.data.id }/`)"
                                    )
                    Paginator(
                        :rows='paginatorAssociados.per_page'
                        :first='(paginatorAssociados.page - 1) * paginatorAssociados.per_page'
                        :totalRecords='paginatorAssociados.count'
                        @page="onPageAssociados")

</template>

<style lang="scss">
    .empresa-salvar {

        .waitingEmpresas-wrapper {
            text-align: center;
            padding: 80px 0;
            .p-progress-spinner {
                width: 60px;
                height: auto;
            }
        }
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .waiting-status{
            width: 25px;
            height: auto;
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 23px;
            height: 23px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            cursor: pointer;
            padding-right:10px;
            &:hover { background-color: #84b553; }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
            .jam {
                color: #fff;
                margin-left:2px;
            }
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import PickList from 'primevue/picklist'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Paginator from 'primevue/paginator'
    import Dialog from 'primevue/dialog'
    import moment from 'moment'
    import BuscarPaciente from '../Paciente/BuscarPaciente'
    import InputSwitch from 'primevue/inputswitch';
    import { Empresa } from './../../middleware'
    import {Utils} from "@/middleware"
    import wsConfigs from './../../middleware/configs'
    import { fixTelefoneFormat } from './../../utils'
    import { required, minLength } from 'vuelidate/lib/validators'
    import DialogUsuario from "@/components/Empresa/DialogUsuario";

    const _ = require('lodash');

    export default {
        components: {
            DialogUsuario,
            ProgressBar, Panel, InputText, Button, Password, ProgressSpinner,
                InputMask, SelectButton, Dropdown, Tooltip, PickList, DataTable, Column,
                Paginator, Dialog, BuscarPaciente, InputSwitch
        },
        directives: { tooltip: Tooltip },
        created () {
            this.getEstados()
            this.getTiposEmpresa()

            let id = parseInt(this.$route.params.id)
            if (! isNaN(id)) this.getEmpresa(id)
        },
        data () {
            return {
                model: {
                    nm_razao_social: null,
                    nm_fantasia: null,
                    nr_cnpj: null,
                    nm_responsavel: null,
                    nm_bairro: null,
                    nm_rua: null,
                    ds_complemento: null,
                    nr_cep: null,
                    nr_empresa: null,
                    nr_telefone: null,
                    nr_whatsapp: null,
                    ds_email: null,
                    cd_tipo_empresa: null,
                    cd_estado: null,
                    ie_iss_retido: false,
                    cd_cidade: null,
                    nm_usuario_cri: null,
                    nm_usuario_edi: null
                },
                options: {
                    especialidades: [],
                    procedimentos: [],
                    tiposEmpresa: [],
                    ufs: [],
                    cidades: [],
                },
                filters: {
                    nm_pessoa_fisica: '',
                    nr_cpf: ''
                },
                waiting: false,
                waitingForm: false,
                waitingAssociados: false,
                usuario: {
                    waiting: false,
                    statusIds: [],
                    dialog: false,
                    model: {}
                },
                waitingTipoEmpresa: false,
                waitingEstado: false,
                waitingCidade: false,
                submitted: false,
                usuarios: [],
                listaAssociados: [],
                paginatorAssociados: {
                    page: this.$route.query.pg || 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                params: {},
                getAssociadosDebounce: _.debounce(function (params, getFunction) {
                    getFunction(params)
                }, 500, { leading: true })
            }
        },
        validations () {
            let v = {
                model: {
                    nm_razao_social: { required, minLength: minLength(2) },
                    nm_fantasia: { required, minLength: minLength(2) },
                    nr_cnpj: { required, minLength: minLength(2) },
                    nm_responsavel: { required, minLength: minLength(2) },
                    nm_bairro: { required, minLength: minLength(2) },
                    nm_rua: { required, minLength: minLength(2) },
                    nr_cep: { required, minLength: minLength(2) },
                    nr_empresa: { required, minLength: minLength(2) },
                    nr_telefone: { required, minLength: minLength(2) },
                    nr_whatsapp: { minLength: minLength(2) },
                    ds_email: { required, minLength: minLength(2) },
                    cd_tipo_empresa: { required },
                    cd_cidade: { required }
                }
            }

            return v
        },
        methods: {
            onpenDialogUsuario(model) {
                this.usuario.model = Object.assign({}, model)
                this.usuario.dialog = true
            },
            getEmpresa(id) {
                if (!id) return
                this.waiting = true
                Empresa.find(id).then(response => {
                    if (response.status === 200) {
                        let keys = Object.keys(this.model)
                        keys.forEach(key => this.model[key] = response.data[key])
                        this.model.id = id
                        this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        if (response.data.dt_atualizado) {
                            this.model.dt_atualizado_f = moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm')
                        }
                        if (this.model.cd_estado) {
                            this.getCidades()
                            this.model.cd_cidade = response.data.cd_cidade
                        }
                        this.filtrarAssociados()
                        this.getUsuariosEmpresa()
                    }
                    this.waiting = false
                })
            },
            getAssociados(params = {}){
                params.cd_empresa = this.model.id
                this.waitingAssociados = true
                Empresa.listarAssociados(params).then( response => {
                    if (response.status === 200) {
                        response.data.results.forEach(paciente => {
                            paciente.dt_nascimento_f = moment(paciente.dt_nascimento).format('DD/MM/YYYY')
                            paciente.ie_sexo_f = ({ M: 'Masculino', F: 'Feminino' })[paciente.ie_sexo]
                            if (paciente.nr_telefone) paciente.nr_telefone_f = fixTelefoneFormat(paciente.nr_telefone)
                            if (paciente.nr_cpf) paciente.nr_cpf_f = paciente.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                            if (paciente.ds_email) {
                                let strMaxLength = 24
                                paciente.ds_email_f = paciente.ds_email.length > strMaxLength ?
                                    `${ paciente.ds_email.substr(0, strMaxLength) }...` : paciente.ds_email
                            }
                        })
                        this.listaAssociados = response.data.results
                        this.paginatorAssociados.count = response.data.count
                    }
                    this.waitingAssociados = false
                })
            },
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)

                let arrConvertOnlyNumbers = ['nr_cnpj', 'nr_telefone', 'nr_whatsapp', 'nr_cep']
                arrConvertOnlyNumbers.forEach(i => { if (dataSend[i]) dataSend[i] = dataSend[i].match(/\d+/g).join('') })

                this.waitingForm = true
                Empresa.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'empresas' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        else Object.values(response.data).forEach(errorMsg => {
                                if (typeof errorMsg == 'object')
                                    this.$toast.error(errorMsg[0], { duration: 3000 })
                                else this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })
            },
            getEstados() {
                this.waitingEstado = true
                Utils.getUFs().then(response => {
                    this.waitingEstado = false
                    this.options.ufs = [{ value: null, text: '- Selecione -', textAlt: '- Selecione -' }]
                    if (response.status === 200) response.data.forEach(uf => {
                        this.options.ufs.push({ value: uf.id, text: uf.ds_sigla, textAlt: uf.nm_estado })
                    })
                })
            },
            getCidades() {
                this.model.cd_cidade = null
                this.options.cidades = []
                if (! this.model.cd_estado) return

                this.waitingCidade = true
                Utils.getMunicipiosEstadoId(this.model.cd_estado).then(response => {
                    this.waitingCidade = false
                    this.options.cidades = [{ value: null, text: '- Selecione -' }]
                    if (response.status === 200) response.data.forEach(cidade => {
                        this.options.cidades.push({ value: cidade.id, text: cidade.nm_cidade })
                    })
                })
            },
            getTiposEmpresa() {
                this.waitingTipoEmpresa = true
                Empresa.getTiposEmpresa().then(response => {
                    this.waitingTipoEmpresa = false
                    if (response.status === 200) {
                        this.options.tiposEmpresa = response.data.map(tipo => {
                            return { value: tipo.id, text: tipo.ds_tipo_empresa }
                        })
                    }
                })
            },
            getUsuariosEmpresa() {
                this.usuario.waiting = true
                Empresa.listRevendedores({ cd_empresa: this.model.id }).then(response => {
                    this.usuario.waiting = false
                    if (response.status === 200) {
                        this.usuarios = response.data.map(usuario => {
                            return {
                                full_name: `${usuario.first_name} ${usuario.last_name}`,
                                ...usuario
                            }
                        })
                    }
                })

            },
            alterarStatusUsuario(usuario) {
                const dataSend = { id: usuario.id, is_active: !usuario.is_active }
                this.usuario.statusIds.push(usuario.id)
                Empresa.saveUsuario(dataSend).then(response => {
                    this.usuario.statusIds = this.usuario.statusIds.filter(x => x !== usuario.id)
                    if (response.status === 200) {
                        usuario.is_active = !usuario.is_active
                    }
                })
            },
            filtrarAssociados(page) {
                this.paginatorAssociados.page = page || 1
                let params = {
                    paginacao: true,
                    page: this.paginatorAssociados.page,
                    per_page: this.paginatorAssociados.per_page
                }
                Object.keys(this.filters).forEach(key => {
                    if (this.filters[key]) {
                        if (key === 'nr_cpf') params[key] = this.filters[key].replace(/(\.|-|_)/g, '')
                        else params[key] = this.filters[key]
                    }
                })
                // Fake loading quando os filtros forem os mesmos
                if (_.isEqual(this.params, params)) {
                    if (!this.waitingAssociados) {
                        this.waitingAssociados = true
                        setTimeout(() => this.waitingAssociados = false, 300)
                    }
                } else {
                    this.params = Object.assign({}, params)
                    this.waitingAssociados = true
                    this.getAssociadosDebounce(params, this.getAssociados)
                }
            },
            onPageAssociados(ev) {
                this.filtrarAssociados(ev.page + 1)
            },
        }
    }
</script>
